import requests from './request';

import Qs from 'qs';

import { getCookie } from '@/utils/mycookie';
// 用户登录
export const userLogin = (d) => requests({ url: '/user/login', method: 'post', headers: { 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 刷新登录验证码
export const changeVerify = () => requests({ url: '/user/verify', method: 'get', headers: { 'x-codic': getCookie('x-myweb-key') } });
// 获取用户列表
export const getUserList = (d) => requests({ url: '/admin/user/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 重置用户密码
export const rePass = (d) => requests({ url: '/admin/user/repass', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const searchUsers = (d) => requests({ url: '/admin/user/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const searchUsers2 = (d) => requests({ url: '/admin/user/search2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取商品列表
export const getProductList = (d) => requests({ url: '/admin/product/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品列表
export const getProductList2 = (d) => requests({ url: '/admin/product/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品详情
export const queryProduct = (d) => requests({ url: '/admin/product/query', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品详情
export const queryProduct2 = (d) => requests({ url: '/admin/product/query2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量删除商品
export const delProducts = (d) => requests({ url: '/admin/product/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 补充商品库存
export const addInventory = (d) => requests({ url: '/admin/product/addi', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProduct = (d) => requests({ url: '/admin/product/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品分类列表
export const getProductTypeList = (d) => requests({ url: '/admin/producttype/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProductType = (d) => requests({ url: '/admin/producttype/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品分类列表
export const getProductBrandList = (d) => requests({ url: '/admin/productbrand/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProductBrand = (d) => requests({ url: '/admin/productbrand/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const deleteProductBrand = (d) => requests({ url: '/admin/productbrand/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 刷新卡密数据
export const reloadCdks = (d) => requests({ url: '/admin/cdk/reload', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询卡密
export const getCdkList = (d) => requests({ url: '/admin/cdk/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询卡密
export const getCdkList2 = (d) => requests({ url: '/admin/cdk/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量添加卡密
export const addProductCdks = (d) => requests({ url: '/admin/cdk/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量删除卡密
export const deleteCdks = (d) => requests({ url: '/admin/cdk/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询卡密信息
export const getCdkInfo = (d) => requests({ url: '/cdk/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询卡密详情
export const getOrderDetail = (d) => requests({ url: '/shop/detail', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 卡密兑换
export const exchangeProduct = (d) => requests({ url: '/shop/exchange', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取系统公告
export const noticeList = (d) => requests({ url: '/admin/sys/notice/all', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取七牛云上传卡密
export const getQiNiuToken = (d) => requests({ url: '/qiniu/token', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取卡密兑换订单
export const getExOrders = (d) => requests({ url: '/admin/exorder/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 删除兑换订单
export const delExOrders = (d) => requests({ url: '/admin/exorder/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询地址信息
export const getAreaInfo = (d) => requests({ url: '/admin/area/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 审核订单信息
export const examineOrder = (d) => requests({ url: '/admin/exorder/examine', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 订单发货申请
export const faHuo = (d) => requests({ url: '/admin/exorder/fahuo', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 搜索订单
export const searchOrders = (d) => requests({ url: '/admin/exorder/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 搜索订单
export const searchOrders2 = (d) => requests({ url: '/admin/exorder/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询消息
export const getMyMess = (d) => requests({ url: '/admin/mess/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 财务入库页面产品分级列表
export const proList = (d) => requests({ url: '/admin/product/ware', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库页面产品分级列表
export const proList2 = (d) => requests({ url: '/admin/product/ware2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const wareList = (d) => requests({ url: '/admin/finance/warehouse/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const outList = (d) => requests({ url: '/admin/finance/warehouse/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const wareList2 = (d) => requests({ url: '/admin/finance/warehouse/list3', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const outList2 = (d) => requests({ url: '/admin/finance/warehouse/list4', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加通知
export const addNotice = (d) => requests({ url: '/admin/sys/notice/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除通知
export const deleteNotices = (d) => requests({ url: 'admin/sys/notice/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const listNotices = (d) => requests({ url: 'admin/sys/notice/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const changeNotice = (d) => requests({ url: 'admin/sys/notice/change', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工领用卡券
export const cardVoucher = (d) => requests({ url: '/admin/finance/cdkuse/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 管理端信息查询
export const adminInfo = (d) => requests({ url: '/admin/info/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 抽奖查询
export const listLottery = (d) => requests({ url: '/admin/product/lottery/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加抽奖
export const addLottery = (d) => requests({ url: '/admin/product/lottery/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 抽奖查询
export const listLottery2 = (d) => requests({ url: '/shop/lottery/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 提交商户申请
export const applyTrader = (d) => requests({ url: '/user/apply', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询商户申请
export const listApplys = (d) => requests({ url: '/admin/user/apply/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 审核商户申请
export const examineApply = (d) => requests({ url: '/admin/user/apply/examine', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除申请
export const deleteApplys = (d) => requests({ url: '/admin/user/apply/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加轮播
export const addLunbo = (d) => requests({ url: '/admin/sys/lunbo/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除轮播
export const deleteLunbos = (d) => requests({ url: '/admin/sys/lunbo/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改轮播
export const updateLunbo = (d) => requests({ url: '/admin/sys/lunbo/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询轮播
export const listLunbo = (d) => requests({ url: '/admin/sys/lunbo/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 主页查询轮播
export const listLunbo2 = (d) => requests({ url: '/sys/lunbo/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取商户信息
export const getFirmInfo = (d) => requests({ url: '/firm/info/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 更新商户信息
export const updateFirmInfo = (d) => requests({ url: '/firm/info/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const userInfos = (d) => requests({ url: '/user/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const infoApply = (d) => requests({ url: '/admin/user/apply/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const searchRoles = (d) => requests({ url: '/admin/role/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const addRole = (d) => requests({ url: '/admin/role/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const searchRoles2 = (d) => requests({ url: '/admin/role/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const updateRole = (d) => requests({ url: '/admin/role/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const deleteRoles = (d) => requests({ url: '/admin/role/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加菜单
export const addMenu = (d) => requests({ url: '/admin/menu/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询菜单
export const listMenu = (d) => requests({ url: '/admin/menu/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询菜单2
export const listMenu2 = (d) => requests({ url: '/admin/menu/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除菜单
export const deleteMenu = (d) => requests({ url: '/admin/menu/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 角色绑定权限
export const bindRam = (d) => requests({ url: '/admin/ram/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工角色权限
export const bindUar = (d) => requests({ url: '/admin/uar/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 搜索员工角色
export const searchUars = (d) => requests({ url: '/admin/uar/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除员工角色绑定
export const deleteUars = (d) => requests({ url: '/admin/uar/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工角色权限
export const updateUar = (d) => requests({ url: '/admin/uar/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 搜索卡券领用
export const listCdkUse = (d) => requests({ url: '/admin/finance/cdkuse/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询日志
export const listLogs = (d) => requests({ url: '/admin/log/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 绑定邮箱
export const bindEmail = (d) => requests({ url: '/admin/email/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 绑定邮箱2
export const bindEmail2 = (d) => requests({ url: '/admin/email/bind2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询pdf数据
export const listPdfs = (d) => requests({ url: '/pdf/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除pdf数据
export const deletePdfs = (d) => requests({ url: '/pdf/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改pdf数据
export const updatePdf = (d) => requests({ url: '/pdf/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改pdf数据
export const updatePdf2 = (d) => requests({ url: '/pdf/update2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });


// 修改pdf数据
export const wxToken = (d) => requests({ url: '/wx/token', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改pdf数据
export const addMp3 = (d) => requests({ url: '/pdf/mp3/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });

// 删除背景音乐
export const deleteMp3 = (d) => requests({ url: '/pdf/mp3/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const deleteUsers = (d) => requests({ url: '/admin/user/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });


// 批量删除用户
export const updateHimg = (d) => requests({ url: '/admin/user/img', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const updateNick = (d) => requests({ url: '/admin/user/name', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const updatePass = (d) => requests({ url: '/admin/user/pass', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });



// 添加pdf
export const addPdf = (d) => requests({ url: '/pdf/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// PDF信息
export const pdfInfo = (i, d) => requests({ url: '/pdf/info/' + i, method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
