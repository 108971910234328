<template>
    <div class="codics-factory-amdin-left" :style="{ width: isCollapse ? '65px' : '270px' }">
        <MenuTree :is-collapse="isCollapse" :menuList="menuList" ref="leftMenu"></MenuTree>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MenuTree from '@/components/MenuTree';
// import { warnCount,listMenu } from '@/api';
import { setCookie, getCookie } from '@/utils/mycookie';
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            nowWarnCount: 0,
            menuList: [
                {
                    id: '1',
                    parentid: '0',
                    name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>后台首页</a>`,
                    icon: '&#xe6d6;',
                    remark: '/admin/home',
                },
                {
                    id: '101',
                    parentid: '0',
                    name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>权限管理</a>`,
                    icon: '&#xe82c;',
                    children: [
                        {
                            id: '113',
                            parentid: '101',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>申请管理</a>`,
                            icon: '',
                            remark: '/admin/apply',
                        },
                        {
                            id: '102',
                            parentid: '101',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>用户管理</a>`,
                            icon: '',
                            remark: '/admin/user',
                        },
                        {
                            id: '111',
                            parentid: '101',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>员工管理</a>`,
                            icon: '',
                            remark: '/admin/staff',
                        },
                        {
                            id: '112',
                            parentid: '101',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>菜单管理</a>`,
                            icon: '',
                            remark: '/admin/menu',
                        }
                    ]
                },
                {
                    id: '201',
                    parentid: '0',
                    name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>模型管理</a>`,
                    icon: '&#xe6bc;',
                    children: [
                        {
                            id: '202',
                            parentid: '201',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>模型管理</a>`,
                            icon: '',
                            remark: '/admin/factory',
                        },
                        {
                            id: '203',
                            parentid: '201',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>坐标管理</a>`,
                            icon: '',
                            remark: '/admin/factory',
                        },
                        {
                            id: '203',
                            parentid: '201',
                            name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>权限分配</a>`,
                            icon: '',
                            remark: '/admin/factory',
                        }
                    ]
                },
                {
                    id: '501',
                    parentid: '0',
                    name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>个人信息</a>`,
                    icon: '&#xe86f;',
                    remark: '/admin/info'
                }
            ]
        }
    },
    methods: {
        async listMenuFuc() {

            var t = await this.userInfo?.user?.ram?.mid.split(',').map(Number);

            let data = await listMenu({});

            if (data.code == 200) {

                if (this.userInfo?.user?.uar?.uid != this.userInfo?.user?.uar?.sid) {

                    this.menuList = await this.initMenu(data.data, t);

                } else {

                    this.menuList = await this.initMenu2(data.data, t);

                }

            } else {

                this.$message.error(data.message);

            }

        },
        async initMenu(d, t) {

            for (var i = d.length - 1; i >= 0; i--) {

                if (d[i].children != null) {

                    let newT = await this.initMenu(d[i].children, t);

                    if (newT == null || newT.length == 0) {

                        let idx = d.indexOf(d[i]);

                        if (idx > -1) {

                            d.splice(idx, 1);

                        }

                    } else {

                        d[i].children = newT;

                    }

                } else {

                    if (d[i].remark == '') {

                        let idx = d.indexOf(d[i]);

                        if (idx > -1) {

                            d.splice(idx, 1);

                        }

                    } else {

                        t = t == null ? [] : t;

                        if (!t.includes(d[i].id)) {

                            let idx = d.indexOf(d[i]);

                            if (idx > -1) {

                                d.splice(idx, 1);

                            }

                        }

                    }

                }

                if (d[i]) {

                    d[i].id = d[i].id.toString();

                    d[i].parentid = d[i].parentid.toString();

                }

            }

            return d

        },
        async initMenu2(d, t) {

            for (var i = d.length - 1; i >= 0; i--) {

                if (d[i].children != null) {

                    let newT = await this.initMenu2(d[i].children, t);

                    d[i].children = newT;

                } else {

                    if (d[i].remark == '') {

                        let idx = d.indexOf(d[i]);

                        if (idx > -1) {

                            d.splice(idx, 1);

                        }

                    }

                }

                d[i].id = d[i].id.toString();

                d[i].parentid = d[i].parentid.toString();

            }

            return d

        }
    },
    mounted() {

        // this.listMenuFuc();

    },
    // methods: {
    //     async warnCountFuc() {

    //         const loading = this.$loading({
    //             lock: true,
    //             text: 'Loading',
    //             spinner: 'el-icon-loading',
    //             background: 'rgba(0, 0, 0, 0.7)'
    //         });

    //         let data = await warnCount();

    //         loading.close();

    //         if (data.code == 200) {

    //             this.$refs.leftMenu.refresh([
    //                 {
    //                     id: '1',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>后台首页</a>`,
    //                     icon: '&#xe6d6;',
    //                     remark: '/admin/home',
    //                 },
    //                 {
    //                     id: '101',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>权限管理</a>`,
    //                     icon: '&#xe82c;',
    //                     children: [
    //                         {
    //                             id: '102',
    //                             parentid: '101',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>用户管理</a>`,
    //                             icon: '',
    //                             remark: '/admin/user',
    //                         },
    //                         {
    //                             id: '111',
    //                             parentid: '101',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>员工管理</a>`,
    //                             icon: '',
    //                             remark: '/admin/staff',
    //                         },
    //                         {
    //                             id: '112',
    //                             parentid: '101',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>菜单管理</a>`,
    //                             icon: '',
    //                             remark: '/admin/menu',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: '201',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>冷库管理</a>`,
    //                     icon: '&#xe6bc;',
    //                     children: [
    //                         {
    //                             id: '202',
    //                             parentid: '201',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>冷库管理</a>`,
    //                             icon: '',
    //                             remark: '/admin/product',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: '301',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备管理</a>`,
    //                     icon: '&#xe74a;',
    //                     children: [
    //                         {
    //                             id: '302',
    //                             parentid: '301',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备管理</a>`,
    //                             icon: '',
    //                             remark: '/admin/device'
    //                         },
    //                         {
    //                             id: '311',
    //                             parentid: '301',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备记录</a>`,
    //                             icon: '',
    //                             remark: '/admin/record'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: '401',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>预警信息</a>`,
    //                     icon: '&#xe83b;',
    //                     children: [
    //                         {
    //                             id: '421',
    //                             parentid: '401',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>预警记录</a><div class='codics-factory-amdin-left-menu-item'>` + (data.data - parseInt(getCookie('com-factory-left-menu-warn-count') || 0)) + `</div>`,
    //                             icon: '',
    //                             remark: '/admin/warn',
    //                         },
    //                         {
    //                             id: '431',
    //                             parentid: '401',
    //                             name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>警报设置</a>`,
    //                             icon: '',
    //                             remark: '/admin/lunbo',
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: '501',
    //                     parentid: '0',
    //                     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>系统设置</a>`,
    //                     icon: '&#xe86f;',
    //                 }
    //             ]);

    //         } else {

    //             this.$message.error(data.message);

    //         }

    //     },
    //     refresh(num) {
    //         this.$refs.leftMenu.refresh([
    //             {
    //                 id: '1',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>后台首页</a>`,
    //                 icon: '&#xe6d6;',
    //                 remark: '/admin/home',
    //             },
    //             {
    //                 id: '101',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>权限管理</a>`,
    //                 icon: '&#xe82c;',
    //                 children: [
    //                     {
    //                         id: '113',
    //                         parentid: '101',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>申请管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/apply',
    //                     },
    //                     {
    //                         id: '102',
    //                         parentid: '101',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>用户管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/user',
    //                     },
    //                     {
    //                         id: '111',
    //                         parentid: '101',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>员工管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/staff',
    //                     },
    //                     {
    //                         id: '112',
    //                         parentid: '101',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>菜单管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/menu',
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: '201',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>冷库管理</a>`,
    //                 icon: '&#xe6bc;',
    //                 children: [
    //                     {
    //                         id: '202',
    //                         parentid: '201',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>冷库管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/factory',
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: '301',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备管理</a>`,
    //                 icon: '&#xe74a;',
    //                 children: [
    //                     {
    //                         id: '302',
    //                         parentid: '301',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备管理</a>`,
    //                         icon: '',
    //                         remark: '/admin/device'
    //                     },
    //                     {
    //                         id: '311',
    //                         parentid: '301',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>设备记录</a>`,
    //                         icon: '',
    //                         remark: '/admin/record'
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: '401',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>预警信息</a>`,
    //                 icon: '&#xe83b;',
    //                 children: [
    //                     {
    //                         id: '421',
    //                         parentid: '401',
    //                         name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>预警记录</a><div class='codics-factory-amdin-left-menu-item'>` + (num - parseInt(getCookie('com-factory-left-menu-warn-count') || 0)) + `</div>`,
    //                         icon: '',
    //                         remark: '/admin/warn',
    //                     },
    //                     // {
    //                     //     id: '431',
    //                     //     parentid: '401',
    //                     //     name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>警报设置</a>`,
    //                     //     icon: '',
    //                     //     remark: '/admin/lunbo',
    //                     // }
    //                 ]
    //             },
    //             {
    //                 id: '501',
    //                 parentid: '0',
    //                 name: `<a style='margin-right:47px;font-weight:bolder;font-size:12px;'>个人信息</a>`,
    //                 icon: '&#xe86f;',
    //                 remark: '/admin/info'
    //             }
    //         ])
    //     }
    // },
    components: {
        MenuTree
    },
    watch: {
        isCollapse(nVal, oVal) {

            setCookie("com-factory-admin-left-state", nVal);

        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
.codics-factory-amdin-left {
    height: 100%;
    position: relative;
    float: left;
    border-right: 1px solid #F5F5F5;
    transition: .3s;
    box-sizing: border-box;
    overflow-y: scroll;
}

.codics-factory-amdin-left-menu:not(.el-menu--collapse) {
    width: 200px;
    /* min-height: 400px; */
}

.codics-factory-amdin-left-menu {
    width: 100% !important;
    border-right: 0px !important;
}

.codics-factory-amdin-left-menu .el-menu-item,
.el-submenu__title {
    height: 45px !important;
    line-height: 45px !important;
    user-select: none !important;
}

.codics-factory-amdin-left-menu .el-menu-item {
    padding-left: 13px !important;
}

.el-menu-item.is-active,
.el-menu div .is-active {
    border-left: 2px solid #62CCA2;
    background: linear-gradient(to right, #FFF, rgba(0, 0, 0, 0));
}

.codics-factory-amdin-left-menu .el-tooltip {
    padding: 0 13px !important;
}

.codics-factory-amdin-left-menu-item {
    height: 15px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 15px;
    line-height: 13px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 4px;
    background: #62CCA2;
    color: #FFF;
    font-size: 8px;
    font-weight: 600;
    border-radius: 7.5px;
    border: 1px solid #FFF;
}

.codics-factory-amdin-left-menu .el-submenu__title {
    padding-left: 13px !important;
}

.codics-factory-amdin-left-menu .el-submenu .codics-menutree-item {
    padding-left: 30px !important;
}
</style>