/*添加cookie*/
export const setCookie = (name, value) => {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";path=/;expires=" + exp.toGMTString();
};

/*读取cookie*/
export const getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
};

/*删除cookie*/
export const delCookie = (name) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";path=/;expires=" + exp.toGMTString();
};


export const setCookieWithExp = (name, value, daysUntilExpire) => {
    var date = new Date();
    date.setTime(date.getTime() + (daysUntilExpire * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookieExp = (cookieName) => {
    const cookies = document.cookie.split('; '); // 将所有cookie分割成数组
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('='); // 将每个cookie分割成[key, value]数组
        if (cookie[0] === cookieName) { // 检查是否是目标cookie
            const expirationStr = cookie.slice(1).join('=').split(';')[0]; // 获取过期时间字符串
            if (expirationStr) {
                console.log(document.cookie)
                const expiration = new Date(expirationStr); // 解析过期时间
                return expiration; // 返回过期时间对象
            }
        }
    }
    return null; // 如果未找到目标cookie或未设置过期时间，则返回null
}
