<template>
    <div class="login-body">
        <!-- <el-image class="codics-pdf-login-bc-img"
            src="https://cdn.bosscaigou.com/huayuan/img/yasuo_%E7%99%BB%E5%BD%95%E8%83%8C%E6%99%AF1.webp" fit="cover"></el-image> -->
        <div class="login-box">
            <div class="login-input-box">
                <div class="login-input-card">
                    <div class="login-input-card-title">登录/注册</div>
                    <el-input v-model="account" maxlength="22" placeholder="请输入账号/邮箱/手机号..."
                        style="margin-bottom: 15px;" clearable></el-input>
                    <el-input v-model="password" maxlength="22" placeholder="请输入账号相应的密码..." :show-password="true"
                        style="margin-bottom: 15px;" clearable></el-input>
                    <el-input placeholder="请输入验证码" maxlength="4" v-model="verify" style="margin-bottom: 15px;"
                        clearable>
                        <template slot="append">
                            <el-image class="login-input-card-code" :src="verifyImg" fit="fill"
                                @click="changeVerifyCode">
                                <div slot="error" class="image-slot"
                                    style="height:fit-content;width: fit-content;position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 0;"
                                    @click="changeVerifyCode">
                                    <i class="el-icon-loading" v-show="verifyState"></i>
                                </div>
                            </el-image>
                            <div style="height: 100%;width: 100%;position: absolute;line-height: 40px;text-align: center;font-size: 13px;left: 0;top: 0;user-select: none;cursor: pointer;"
                                v-show="!verifyState" @click="changeVerifyCode(); verifyState = true;">加载验证码</div>
                        </template>
                    </el-input>
                    <el-button class="login-input-card-login" type="warning" plain @click="userLogin">登录/注册</el-button>
                    <!-- <div class=""></div> -->
                    <!-- <el-checkbox v-show="false" v-model="agreeState"
                        style="font-size: 13px;left: 50%;transform: translateX(-50%);">我已阅读并同意<a
                            style="color: #E6A23C; cursor: pointer;"
                            @click="nowType = 0; drawer = true; agreeState = !agreeState;"> 用户协议 </a>和<a
                            style="color: #E6A23C; cursor: pointer;"
                            @click="nowType = 1; drawer = true; agreeState = !agreeState;">
                            隐私政策</a></el-checkbox> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { userLogin, changeVerify } from '@/api';
import { setCookie, getCookie } from '@/utils/mycookie';
import { jumpTo } from '@/utils/myutils';
export default {
    name: 'ShopIndex',
    props: {
        messDirection: {
            type: String,
            default: 'rtl'
        }
    },
    data() {
        return {
            account: '',
            password: '',
            verify: '',
            verifyImg: '',
            drawer: false,
            verifyState: false,
            agreeState: true,
            agreeTxt: '',
            nowType: 0,
            policyTxt: ''
        };
    },
    mounted() {
        this.changeVerifyCode();
    },
    methods: {
        async changeVerifyCode() {

            let data = await changeVerify();

            if (data.code == 200) this.verifyState = true;

            // console.log(data);

            this.verifyImg = data.data;

        },
        handleClose(done) {
            done();
        },
        async userLogin() {

            if (this.account == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的账号不能为空！',
                    type: 'warning'
                });
                return;
            }

            if (this.password == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的密码不能为空！',
                    type: 'warning'
                });
                return;
            }

            if (this.verify == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的验证码不能为空！',
                    type: 'warning'
                });
                return;
            }

            if (!this.agreeState) {
                console.log(this.agreeState);
                this.$notify({
                    title: '警告',
                    message: '请先勾选用户协议以及隐私政策！',
                    type: 'warning'
                });
                return;
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await userLogin({ a: this.account, p: this.password, v: this.verify });
            loading.close();
            if (data.code == 200) {

                this.login(data);

            } else {

                this.$message.error(data.message);

            }

        },
        toPage(y, p, q) {
            jumpTo(this, y, p, q);
        },
        login(data) {

            // console.log(data);

            this.$message({
                message: data.message,
                type: 'success'
            });

            setCookie("user-inf", JSON.stringify(data));
            // 刷新仓库数据
            this.$store.dispatch('login');

            jumpTo(this, data.user.member == 0 ? '后台' : (this, data.user.member == 1 ? '商户中心' : '商户申请'), {}, {});

        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
};
</script>

<style lang="css">
/* 登录页面 */
.login-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #F4C720;
}

.codics-pdf-login-bc-img{
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
}

/* 登录页面盒子 */
.login-box {
    height: 100%;
    width: 100%;
    position: relative;
    /* max-width: 1170px; */
    left: 50%;
    transform: translateX(-50%);
}

/* 登陆页面轮播盒子 */
.login-box-lunbo-box {
    height: 100%;
    width: 63%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border-right: 1px solid;
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #ededed, rgba(0, 0, 0, 0)) 2;
}

/* 登陆页面轮播 */
.login-box-lunbo {
    height: 80%;
    width: 80%;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* 轮播子控件 */
.el-carousel-item {
    height: 100%;
    width: 100%;
}

/* 登陆页面输入盒子 */
.login-input-box {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

/* 登陆内容容器 */
.login-input-card {
    height: fit-content;
    width: fit-content;
    max-width: 280px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* 登录页面文本标题 */
.login-input-card-title {
    height: 80px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 80px;
    text-align: center;
    font-size: 19px;
    font-weight: 800;
    color: #333;
}

/* 登录按钮 */
.login-input-card-login {
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 15px !important;
}

/* //修改选择框的颜色 */
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #E6A23C !important;
    background-color: #E6A23C !important;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #E6A23C !important;
}

/* //修改选中后文本的颜色 */
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #E6A23C !important;
}

.el-input-group__append {
    padding: 0 10px 0 10px !important;
}

/* 验证码图片 */
.login-input-card-code {
    height: 25px;
    width: 62.5px;
    top: 2.5px;
    box-sizing: border-box;
    cursor: pointer;
    color: #333;
    font-size: 11px;
}

.codics-login-dialog-content {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
    font-size: 14px;
    color: #999;
    line-height: 2;
    white-space: break-spaces;
}

/* 适配移动端 */
@media (max-width:768px) {
    .login-box-lunbo-box {
        display: none !important;
    }

    .login-input-box {
        width: 100% !important;
    }

    .login-input-card {
        max-width: 80% !important;
    }
}
</style>