<template>
    <div class="codics-factory-admin-body">
        <AdminHeader @changeCollapse="changeCollapse" :is-collapse="isCollapse" ref="adminHeader"></AdminHeader>
        <div class="codics-factory-admin-view">
            <div class="codics-factory-admin-left-box"
                :style="{ width: isCollapse ? '45px !important' : '250px !important' }">
                <FirmLeft :is-collapse="isCollapse" ref="adminLeft"></FirmLeft>
            </div>
            <router-view class="codics-factory-admin-content" @check-mem="checkMem"
                :style="{ width: isCollapse ? 'calc(100% - 47px)' : 'calc(100% - 252px)' }"
                ref="routeViw"></router-view>
        </div>
    </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader';
import FirmLeft from '@/components/FirmLeft';
import { getCookie } from '@/utils/mycookie';
export default {
    data() {
        return {
            isCollapse: !Boolean(getCookie("com-factory-admin-left-state") == null || getCookie("com-factory-admin-left-state") == 'false')
        }
    },
    methods: {
        changeCollapse() {
            this.isCollapse = !this.isCollapse;
        },
        checkMem() {
            let pageId = this.$route.matched[this.$route.matched.length - 1].meta.pageId;
            if (pageId != null && pageId != -1 && this.userInfo?.user?.uar?.rid != -1 && !this.userInfo?.user?.ram?.mid.split(',').map(Number).includes(pageId)) {

                this.$message({
                    message: '帐号没有访问当前页面的权限！',
                    type: 'warning',
                    showClose: true
                });

                jumpTo(this, "商户首页", {}, {});

            }
        },

    },
    mounted() {
        this.$emit('check-mem');
    },
    components: {
        AdminHeader,
        FirmLeft
    }
}
</script>

<style>
.codics-factory-admin-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
}

.codics-factory-admin-view {
    height: calc(100% - 46px);
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
}

.codics-factory-admin-left-box {
    height: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    transition: .3s;
    border-right: 1px solid #F0F0F0;
}

.codics-factory-admin-content {
    height: 100% !important;
    position: relative;
    float: left;
    box-sizing: border-box;
    transition: .3s;
    overflow-y: scroll !important;
    background: #FFF !important;
}
</style>