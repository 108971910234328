<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {
    return {
      title: this.$route.meta.metaInfo.title,
      meta: [
        { name: "keywords", content: this.$route.meta.metaInfo.keywords },
        { name: "description", content: this.$route.meta.metaInfo.description },
      ]
    }
  }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  background: #f8f8f8;
}
</style>
