<template>
    <div class="fa_container">
        <!-- 存放three.js的渲染效果 -->
        <div class="container" ref="container"></div>
        <div class="card" id="cardId">模型标签</div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
// import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const scene = new THREE.Scene()
let renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    logarithmicDepthBuffer: true
})
let cssRenderer = new CSS2DRenderer()
// let css3Renderer = new CSS3DRenderer()
let camera, controls
// let loader = new GLTFLoader()

export default {
    name: 'model',

    mounted() {
        this.init()
        this.render()
    },

    methods: {
        init() {
            this.dom = this.$refs['container']

            // 坐标轴+灯光
            const axes = new THREE.AxesHelper(50)
            const directLight = new THREE.AmbientLight(0x404040, 2)
            scene.add(axes, directLight)

            // 相机
            camera = new THREE.PerspectiveCamera(
                45,
                this.dom.offsetWidth / this.dom.offsetHeight,
                0.001,
                10000
            )
            camera.position.set(25, 25, 25)

            // 控制器（注意这里的第二个参数，如果为renderer.domElement页面不能放大缩小）
            controls = new OrbitControls(camera, cssRenderer.domElement)

            // 1、WebGLRenderer渲染器：用于渲染三维模型
            renderer.setSize(1902, 935)
            renderer.outputEncoding = THREE.sRGBEncoding
            this.dom.appendChild(renderer.domElement)

            // 2、把标签DOM转化为css2D元素，相当于在标签DOM外添加一个div
            this.compDiv = document.getElementById('cardId')
            this.compTag = new CSS2DObject(this.compDiv)
            // this.compTag.scale.set(0.04, 0.04, 0.04) // 对标签进行缩放
            scene.add(this.compTag)

            // 3、css2D渲染器：用于渲染模型的2D或者3D标签
            cssRenderer.setSize(1902, 935)
            // 设置position和top属性，把转化后的css2D标签和原来的页面重叠
            cssRenderer.domElement.style.position = 'absolute'
            cssRenderer.domElement.style.top = 0
            this.dom.appendChild(cssRenderer.domElement)

            // 创建一个球
            const spherGeometry = new THREE.SphereGeometry(5, 32, 16)
            const spherMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color('0xffffff') })
            const circle = new THREE.Mesh(spherGeometry, spherMaterial)
            scene.add(circle)
        },

        render() {
            requestAnimationFrame(this.render.bind(this))
            controls.update()
            // 注意这两个渲染器都需要，缺少会导致模型或者标签不能展示
            cssRenderer.render(scene, camera)
            renderer.render(scene, camera)
        }
    }
}
</script>

<style>
.container {
    width: 1902px;
    height: 935px;
    overflow: hidden;
    background: black;
    display: inline-block;
}

.card {
    height: 200px;
    width: 200px;
    position: absolute;
    display: inline-block;
    color: white;
    background: blueviolet;
}
</style>